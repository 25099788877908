<template>
  <div class="container" style="position: relative">
    <el-button type="primary" v-on:click="print">打印本页报告</el-button>
    <!--        <p class="value" style="font-size: 12px">鼠标在本窗口任意位置点击右键可以打印本页面，或者输入快捷键 Ctrl + P 打印检测报告</p>-->
    <section class="header">
      <h1>{{ loginData.userfullname }}</h1>
      <h3>智孕帮AI心理测评检测({{ reportData.pregnancytypename }})报告</h3>
    </section>
    <div class="divider"></div>
    <!--  基本信息   -->
    <section class="basic" v-if="basicInfo">
      <el-row>
        <el-col :span="8">
          <p>
            <span class="label">姓名：</span>
            <span class="value">
              {{ basicInfo.name }}
            </span>
          </p>
        </el-col>
        <el-col :span="8">
          <p>
            <span class="label">出生日期：</span>
            <span class="value">
              {{ basicInfo.age }}
            </span>
          </p>
        </el-col>
        <el-col :span="8">
          <p>
            <span class="label">民族：</span>
            <span class="value">
              {{ basicInfo.mz }}
            </span>
          </p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <p>
            <span class="label">婚姻状态：</span>
            <span class="value">
              {{ basicInfo.hyzk }}
            </span>
          </p>
        </el-col>
        <el-col :span="8">
          <p>
            <span class="label">末次月经时间：</span>
            <span class="value">
              {{ basicInfo.mcyjsj }}
            </span>
          </p>
        </el-col>
        <el-col :span="8">
          <p>
            <span class="label">怀孕次数：</span>
            <span class="value">
              {{ basicInfo.hys }}
            </span>
          </p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <p>
            <span class="label">分娩次数：</span>
            <span class="value">
              {{ basicInfo.fmcs }}
            </span>
          </p>
        </el-col>
        <el-col :span="8">
          <p>
            <span class="label">躯体疾病：</span>
            <span class="value">
              {{ basicInfo.qtjb }}
            </span>
          </p>
        </el-col>
        <el-col :span="8">
          <p>
            <span class="label">文化程度：</span>
            <span class="value">
              {{ basicInfo.whcd }}
            </span>
          </p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <p>
            <span class="label">家庭月收入：</span>
            <span class="value">
              {{ basicInfo.jtysr }}
            </span>
          </p>
        </el-col>
        <el-col :span="8">
          <p>
            <span class="label">医疗付费方式：</span>
            <span class="value">
              {{ basicInfo.fffs }}
            </span>
          </p>
        </el-col>
        <el-col :span="8">
          <p>
            <span class="label">工作状况：</span>
            <span class="value">
              {{ basicInfo.gzzk }}
            </span>
          </p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <p>
            <span class="label">流产经历（次数）：</span>
            <span class="value">
              {{ basicInfo.lcjl }}
            </span>
          </p>
        </el-col>
        <el-col :span="8">
          <p>
            <span class="label">妊娠合并症（种）：</span>
            <span class="value">
              {{ basicInfo.rchbz }}
            </span>
          </p>
        </el-col>
        <el-col :span="8">
          <p>
            <span class="label">不良妊娠次数：</span>
            <span class="value">
              {{ basicInfo.blrc }}
            </span>
          </p>
        </el-col>
      </el-row>
    </section>
    <div class="divider"></div>
    <!--   评测结果    -->
    <section>
      <p>
        <span class="label">本次评估结果</span>
      </p>
      <table>
        <tr>
          <th>量表名称</th>
          <th>得分</th>
          <th>状况评级</th>
          <th>备注</th>
        </tr>
        <tr v-for="item in reportData.reportcontext" v-bind:key="item.typecode">
          <td style="min-width: 100px">{{ item.typename }}</td>
          <td style="min-width: 130px">
            {{ item.quotascore
            }}<template v-if="item.quotatemplate">{{
              "（参考值" + item.quotatemplate + ")"
            }}</template>
          </td>
          <td style="min-width: 130px">{{ item.result }}</td>
          <td>{{ item.proposal }}</td>
        </tr>
        <template v-if="scl90Info">
          <tr
            v-for="(quota, index) in scl90Info.quotaList"
            v-bind:key="quota.proposal"
          >
            <td v-if="index === 0" rowspan="10" style="min-width: 100px">
              {{ scl90Info.typename }}
            </td>
            <td style="min-width: 100px">{{ quota.quotascore }}</td>
            <td style="min-width: 100px">{{ quota.result }}</td>
            <td>{{ quota.proposal }}</td>
          </tr>
        </template>
        <tr v-if="scl90Info">
          <td style="min-width: 100px">SCL-90量表总结</td>
          <td style="min-width: 100px">{{ scl90Info.quotascore }}</td>
          <td style="min-width: 100px">{{ scl90Info.result }}</td>
          <td>{{ scl90Info.proposal }}</td>
        </tr>
      </table>
    </section>
    <!--  图表   -->
    <div class="divider"></div>
    <section
      v-for="item in reportData.reportcontext"
      v-bind:key="item.typecode"
    >
      <p>
        <span class="label">{{ item.typename }}</span>
      </p>
      <el-row>
        <el-col :span="12">
          <p class="value">
            {{ item.proposal }}
          </p>
        </el-col>
        <el-col :span="12">
          <div
            v-bind:id="'charts-' + item.typecode"
            style="width: 325px; height: 400px"
          ></div>
        </el-col>
      </el-row>
      <div class="divider"></div>
    </section>
    <section v-if="scl90Info">
      <p>
        <span class="label">{{ scl90Info.typename }}</span>
      </p>
      <el-row>
        <el-col :span="24">
          <p class="value">
            {{ scl90Info.proposal }}
          </p>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24">
          <div
            v-bind:id="'charts-' + scl90Info.typecode"
            style="width: 650px; height: 400px"
          ></div>
        </el-col>
      </el-row>
      <template v-if="scl90Info">
        <el-row
          v-for="quota in scl90Info.quotaList"
          v-bind:key="quota.proposal"
        >
          <el-row>
            <el-col :span="24">
              <p>
                <span class="label"
                  >{{ quota.quotaname }}{{ quota.quotascore + "分" }}</span
                >
              </p>
              <p>
                <span class="value">{{ quota.proposal }}</span>
              </p>
            </el-col>
          </el-row>
        </el-row>
      </template>
    </section>
    <div class="divider"></div>
    <!--   总结    -->
    <section class="result">
      <p>
        <span class="label">报告总结</span>
      </p>
      {{ reportData.assessdetail }}
    </section>
    <div style="height: 100px"></div>
  </div>
</template>

<script>
import router from "../router";
import * as echarts from "echarts";

export default {
  name: "ViewReportDetails",
  data() {
    return {
      reportData: null,
      loginData: null,
      basicInfo: null,
      scl90Info: null,
    };
  },
  created() {
    if (!router.currentRoute.query.data) {
      alert("无内容");
      return;
    }
    this.loginData = JSON.parse(localStorage.getItem("logindata"));
    this.reportData = JSON.parse(decodeURI(router.currentRoute.query.data));
    let data = this.reportData.reportcontext;
    console.log(this.reportData);
    if (data[0].typecode === "001") {
      this.basicInfo = data.shift();
    }
    for (let index in data) {
      let item = data[index];
      if (item.typecode === "005") {
        this.scl90Info = item;
      }
    }
    data.remove(this.scl90Info);
    document.title =
      "智孕帮AI心理测评检测(" +
      this.reportData.pregnancytypename +
      ")报告-" +
      this.basicInfo.name;
  },
  mounted() {
    for (let index in this.reportData.reportcontext) {
      let item = this.reportData.reportcontext[index];
      if (!item.typecode) continue;
      let myChart = echarts.init(
        document.getElementById("charts-" + item.typecode)
      );
      myChart.setOption(
        createChartOptions(
          ["", "", item.typename, "", ""],
          [0, 0, item.quotascore, 0, 0]
        )
      );
    }
    if (this.scl90Info) {
      let myChart = echarts.init(
        document.getElementById("charts-" + this.scl90Info.typecode)
      );
      let names = [];
      let values = [];
      for (let index in this.scl90Info.quotaList) {
        let quota = this.scl90Info.quotaList[index];
        if (!quota.quotaname || !quota.quotascore) continue;
        names.push(quota.result);
        values.push(quota.quotascore);
      }
      myChart.setOption(createChartOptions(names, values));
    }
  },
  methods: {
    print() {
      window.print();
    },
  },
};

function createChartOptions(name, value) {
  return {
    legend: {
      data: [""],
    },
    xAxis: {
      data: name,
    },
    yAxis: {},
    series: [
      {
        name: "",
        label: {
          show: true,
          position: "inside",
        },
        type: "bar",
        data: value,
      },
    ],
  };
}

Array.prototype.remove = function (b) {
  var a = this.indexOf(b);
  if (a >= 0) {
    this.splice(a, 1);
    return true;
  }
  return false;
};
</script>

<style scoped media="print">
.container {
  width: 650px;
  margin: 0 auto;
}
.header {
  text-align: center;
}
.label {
  line-height: 30px;
  color: #303133;
  font-weight: bold;
  font-size: 14px;
}
.value {
  line-height: 30px;
  color: #333333;
  font-size: 14px;
}
.basic {
}
.result {
  color: #333333;
  font-size: 15px;
}
table {
  width: 100%;
  text-align: left;
  border-width: 1px;
  border-color: #333333;
  border-collapse: collapse;
  font-size: 15px;
}
th {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #333333;
  background-color: #dedede;
}
td {
  border-width: 1px;
  padding: 8px;
  border-style: solid;
  border-color: #333333;
  background-color: #ffffff;
}
.divider {
  background: #333333;
  height: 1px;
  margin-top: 10px;
  margin-bottom: 10px;
}
p {
  margin-top: 5px;
  margin-bottom: 5px;
}
.none {
  display: none;
}
</style>